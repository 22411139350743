import React  from "react";

import "../../styles/login.css";
import SinglePage from "../../components/template/SinglePage";

function ConfirmarEmail () {

  return (
    <SinglePage>
      <div style={{marginBottom: 15}}>
        <p className="title-md">Verificação de e-mail</p>
        <span className="description-md">
            <strong>2º Passo:</strong> Cheque seu e-mail<br/>
          </span>

        <p className="description-md" style={{marginTop: '1rem'}}>
          Precisamos que você abra o link de verificação de e-mail que enviamos para você!
        </p>

        <span>
          <i style={{color: 'var(--warning)', marginRight: '8px'}} className="fa fa-exclamation-triangle"/>
          Obs.: Verifique também a sua caixa de spam.
        </span>
      </div>

    </SinglePage>
  );
}

export default ConfirmarEmail;

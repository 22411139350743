import React, {useState, useEffect, useContext} from "react";

import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import {pageNavigationPlugin} from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import API from "../../services/api";
import history from '../../routers/History';
import SignDocument from "./SignDocument";
import BaixarDocumento from "./BaixarDocumento";
import DocumentSignatories from "./DocumentSignatories";
import queryString from "query-string";
import {handleError} from "../../utils/handleError";
import {getStatus, getStatusColor} from "../../utils/documents/documentStatus";
import ProcessClosed from "./ProcessClosed";
import EnableNotifications from "../notifications/EnableNotifications";
import DocumentOptions from "./DocumentOptions";
import ButtonDefault from "../template/buttons/ButtonDefault";
import {ARCHIVE, GET_DOCUMENT} from "../../services/documentService";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";
import CheckMobile from "../../utils/checkMobile";
import Progress from "../template/Progress";
import {parseDateToBR} from "../../utils/dataUtils";
import fotoPerfil from "../../images/logo_fundo_640_640.jpeg";
import apolloClient from "../../services/apolloClient";
import DOCUMENT_URL from "../../querys/documentos/getLinkDocumento";
import {Card, CardBody} from "reactstrap";
import EditSendTo from "./EditSendTo";
import {isAuthorized, PERMISSIONS} from "../../utils/permission/checkPermission";
import {Offcanvas} from "react-bootstrap";
import {Context} from "../../routers/Context/AuthContext";

function Documento () {

  const [document, setDocument] = useState(null)
  const [progress, setProgress] = useState(true)
  const [progressPDF, setProgressPDF] = useState(true)
  const [pdf, setPDF] = useState(null)
  const [showPanel, setShowPanel] = useState(false)
  const zoomPluginInstance = zoomPlugin()
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { CurrentPageLabel } = pageNavigationPluginInstance;
  const checkMobile = CheckMobile()
  const { darkMode } = useContext(Context)

  useEffect (() => {
    getDocument()
    getPDF()
  }, []);

  function getDocument () {
    setProgress(true)
    const documentId = history.location.pathname.split("/documentos/")[1];
    API.get(GET_DOCUMENT+documentId)
      .then((response) => {
        const result = response.data.data
        setDocument(result)
      }).catch((error) => {
      handleError(error.response.data)
    })
      .finally(() => setProgress(false))
  }

  const getPDF = () => {
    const documentId = history.location.pathname.split("/documentos/")[1];
    setProgressPDF(true)
    apolloClient.mutate({
      mutation: DOCUMENT_URL,
      variables: {id: documentId},
      fetchPolicy: 'no-cache'
    })
      .then(result => {
        setPDF(result.data.documento.url)
      })
      .catch(() => {
        showNotification(
          "",
          "Não foi possível baixar o documento. Tente novamente mais tarde!",
          NOTIFICATION_TYPE.danger,
          NOTIFICATION_POSITION.center
        )
      })
      .finally(() => setProgressPDF(false))
  }

  const close = () => {
    const {redirectTo} = queryString.parseUrl(window.location.href).query;
    history.push(redirectTo ? redirectTo : "/documentos");
  }

  return (
    <>
      <div className="row">
        <div className="col-md-4 order-sm-last">
          <div className="card card-mobile" style={{marginBottom: 0}}>
            {
              progress ?
                <div className="card-body">
                  <Progress />
                </div>
                :
                <>
                  <div className="card-header p-0 border-bottom-0">
                    <div style={{padding: '0 1rem'}} className="d-flex justify-content-between align-items-center">
                      <h4 className="my-3">{document.name}</h4>
                      <div>
                        <i title="Fechar documento" style={{color: 'var(--danger)', cursor: 'pointer'}}
                           className="fa fa-times" onClick={() => close()}>
                        </i>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    {
                      checkMobile ?
                        <div style={{marginBottom: '.5rem'}}>
                          <button type="button" className="btn btn-assinauai btn-block"
                                  onClick={() => setShowPanel(true)}>
                            <i className="fas fa-signature"/> Assinar documento
                          </button>
                        </div>
                        :
                        <Signature
                          document={document}
                          getDocument={() => getDocument()}
                          changeDocument={(document) => setDocument(document)}
                          getPDF={() => getPDF()} />
                    }
                  </div>
                </>
            }
          </div>
        </div>

        <div className="col-md-8" >
          <Card className="card-mobile">
            <CardBody>
              <div style={{marginBottom: '1.5rem'}} className="d-flex justify-content-around align-items-center">
                <CurrentPageLabel>
                  {(props) => (
                    <span>Página {`${props.currentPage + 1} de ${props.numberOfPages}`}</span>
                  )}
                </CurrentPageLabel>

                <div className="d-flex justify-content-center align-items-center">
                  <ZoomOut>
                    {(props) => (
                      <button className="btn btn-default" onClick={props.onClick}>
                        <i className="fas fa-search-minus"/>
                      </button>
                    )}
                  </ZoomOut>

                  <div style={{ padding: '0px 8px' }}>
                    <CurrentScale>
                      {(props) => <strong style={{color: 'var(--au-orange)'}}>{`${Math.round(props.scale * 100)}%`}</strong>}
                    </CurrentScale>
                  </div>

                  <ZoomIn>
                    {(props) => (
                      <button className="btn btn-default" onClick={props.onClick}>
                        <i className="fas fa-search-plus"/>
                      </button>
                    )}
                  </ZoomIn>
                </div>
              </div>
              <div style={{height: '750px'}}>
                {
                  progressPDF ?
                    <Progress />
                    :
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                      <Viewer
                        class="pdf-view"
                        fileUrl={pdf}
                        defaultScale={SpecialZoomLevel.PageFit}
                        plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
                      />
                    </Worker>
                }
              </div>
            </CardBody>
          </Card>
        </div>
      </div>

      <Offcanvas show={showPanel}
                 onHide={() => {setShowPanel(false)}}
                 key={2} className={`${darkMode ? 'dark-mode' : ''}`}
                 name="end" placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Assinar documento</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Signature
            document={document}
            getDocument={() => getDocument()}
            changeDocument={(document) => setDocument(document)}
            getPDF={() => getPDF()} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

function Signature({document, getDocument, changeDocument, getPDF}) {

  const [archiving, setArchiving] = useState(false)
  const isProcessClosed = () => {
    return document.status !== 'pending';
  }

  const archive = (id, archive) => {
    setArchiving(true)
    API.post(ARCHIVE, {
      documents: id, archive
    })
      .then(() => {
        getDocument()
        showNotification(
          "",
          archive ? "Documento arquivado" : "Documento desarquivado",
          NOTIFICATION_TYPE.success,
          NOTIFICATION_POSITION.topRight
        )
      })
      .catch((error) => {
        handleError(error.response.data);
      })
      .finally(() => setArchiving(false))
  }

  return (
    <>
      <div className="d-flex align-items-center" style={{marginBottom: '1rem'}}>
        <div style={{marginRight: 8}} className="image">
          <img style={{width: 40}} src={
            document.profilePicture ? document.profilePicture : fotoPerfil
          } className="img-circle elevation-2" alt="Foto do assinante" />
        </div>

        <div>
          <p style={{fontSize: '12px', marginBottom: 0}} className="text-muted"><strong>{document.sentBy}</strong></p>
          <p style={{fontSize: '12px', marginBottom: 0}} className="text-muted">{document.email}</p>
        </div>
      </div>
      <p style={{fontSize: '14px', marginBottom: 0}} className="text-muted">
        <i className="fa fa-calendar"/> Recebido em: {parseDateToBR(document.createdAt)}
      </p>
      { document.deadline ? <p style={{fontSize: '14px', marginBottom: 0}} className="text-muted">
        <i className="fa fa-clock"/> Prazo: {parseDateToBR(document.deadline)}</p> : null
      }
      { document.cancellationDate ?
        <p style={{fontSize: '14px', marginBottom: 0}} className="text-muted">
          <i className="fas fa-ban" style={{color: "var(--au-red)"}}/> Cancelado em: {parseDateToBR(document.cancellationDate)}
        </p>
        :
        null
      }
      {
        document.isArchived ?
          <p style={{fontSize: 14, marginBottom: 0}} className="text-muted archived">
            <i className="fa fa-archive" /> Documento arquivado
          </p>
          :
          null
      }
      <p style={{fontSize: '14px', marginBottom: 0, marginTop: '1rem'}} className="text-muted">
        <strong>Status do processo:</strong><br/>
        <i className="fas fa-flag" style={{color: getStatusColor(document)}} /> {getStatus(document)}
      </p>

      <div className="d-flex justify-content-around align-items-center" style={{marginTop: '15px'}}>
        <BaixarDocumento docId={document.id}/>
        {
          !document.isArchived ?
            <ButtonDefault
              text="Arquivar"
              action={() => archive([{id: document.id}], true)}
              progress={archiving}
              icon="fa fa-archive"
              style="btn-sm" />
            :
            <ButtonDefault
              text="Desarquivar"
              action={() => archive([{id: document.id}], false)}
              progress={archiving}
              icon="fa fa-archive"
              style="btn-sm" />
        }
      </div>

      <div style={{marginTop: 8}}>
        <EditSendTo
          documentId={document.id}
          sendTo={document.sendTo}
          onResult={(result) => {
            changeDocument({
              ...document,
              sendTo: {
                ...result
              }
            })
          }}/>
      </div>

      <div className="row">
        <div className="col-md-12">
          <EnableNotifications />
        </div>
      </div>

      <hr />

      {
        document.signedIn == null &&  document.notSignedIn == null && document.isSignatory?
          <>
            {
              !isProcessClosed() ?
                <>
                  {
                    isAuthorized(PERMISSIONS.signature) ?
                      <>
                        <SignDocument refresh={() => {
                          getDocument()
                          getPDF()
                        }} document={document} />

                        <hr />
                      </>
                      :
                      null
                  }
                </>
                :
                <>
                  <ProcessClosed isArchived={document.isArchived} />
                  <hr />
                </>
            }
          </>
          :
          null
      }

      <div>
        <h6 className="my-3"><strong>Signatários</strong></h6>
        <DocumentSignatories signatories={document.signatories} />
      </div>

      {
        !isProcessClosed() ?
          <DocumentOptions document={document} updateDocument={() => getDocument()} />
          :
          null
      }
    </>
  )
}

export default Documento;

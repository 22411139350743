import React, {useContext, useEffect, useState} from "react";

import { store } from "react-notifications-component";

import API from "../services/api";
import { UploadDocumento } from "../components/documentos/UploadDocumento";
import SignatariosDocumento from "../components/documentos/SignatariosDocumento";
import RegrasProcesso from "../components/documentos/RegrasProcesso";
import getTitle, {handleError} from "../utils/handleError";
import {NEW_DOCUMENT} from "../services/documentService";
import Tour from "reactour";
import {newDocumentTutorial} from "../utils/tutorial/newDocumentTutorial";
import ModalError from "../components/utils/ModalError";
import history from "../routers/History";
import {Modal} from "react-bootstrap";
import {SpecialZoomLevel, Viewer, Worker} from "@react-pdf-viewer/core";
import {getAnalytics, logEvent} from "firebase/analytics";
import SendTo from "../components/documentos/SendTo";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../utils/notifications";
import Consumption from "../components/payment/Consumption";
import IconButton from "../components/template/buttons/IconButton";
import {Context} from "../routers/Context/AuthContext";

class NovoDocumento extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      nomeArquivo: "",
      signatarios: [],
      prazoAss: "",
      showProgress: false,
      arquivo: null,
      tituloDocumento: "",
      isTourOpen: false,
      sendTo: {
        message: "",
        emails: []
      },
      showPDF: false,
      fileURL: null,
      error: null
    };
  }

  limparUpload () {
    this.setState({
      nomeArquivo: '',
      signatarios: [],
      prazoAss: '',
      arquivo: null,
      tooltipNomeDocumento: false,
      tooltipData: false,
      editarNome: false,
      sendTo: {
        message: "",
        emails: []
      }
    })
  }

  addSignatario(contato) {
    contato = {
      ...contato,
      pasta: null
    }
    const signatarios = this.state.signatarios;
    if (!this.signatarioJaAdicionado(contato.id)) {
      signatarios.push(contato);
      this.setState({signatarios: signatarios});
    }
  }

  vincularPasta(contato, pasta) {
    const signatarios = this.state.signatarios;
    signatarios.map(signatario => {
      if (signatario.id === contato.id) {
        signatario.pasta = pasta;
      }
    })
    this.setState({signatarios: signatarios});
  }

  signatarioJaAdicionado(contatoId) {
    const signatarios = this.state.signatarios;
    return signatarios.filter(signatario => signatario.id === contatoId).length > 0;
  }

  rmSignatario(index) {
    const signatarios = this.state.signatarios;
    signatarios.splice(index, 1);
    this.setState({signatarios: signatarios});
  }

  addEmail = (email) => {
    if (!this.hasEmail(email.trim())) {
      const newEmails = Array.from(this.state.sendTo.emails)
      newEmails.push(email.trim())
      this.setState({
        sendTo: {
          ...this.state.sendTo,
          emails: newEmails
        }
      })
    }
  }

  hasEmail = (email) => {
    return this.state.sendTo.emails.filter((e) => e === email).length === 1
  }

  removeEmail = (index) => {
    const newEmails = Array.from(this.state.sendTo.emails)
    newEmails.splice(index, 1)
    this.setState({
      sendTo: {
        ...this.state.sendTo,
        emails: newEmails
      }
    })
  }

  selecionarDocumento(arquivo) {
    this.setState({
      arquivo: arquivo,
      tituloDocumento: arquivo.name.split('.')[0],
      nomeArquivo: arquivo.name.split('.')[0],
      editarNome: false,
      fileURL: window.URL.createObjectURL(arquivo)
    });
  }

  cadastrarDocumento () {
    const analytics = getAnalytics()
    this.setState({showProgress: true});

    let formData = new FormData(),
      config = { headers: { "content-type": "multipart/form-data" }};

    this.state.signatarios.map((signatario, index) => {
      formData.append(`contacts[${index}][id]`, signatario.id);
    });
    this.state.sendTo.emails.map((email, index) => {
      formData.append(`sendTo[emails][${index}]`, email);
    });
    if (this.state.sendTo.emails.length > 0) {
      formData.append(`sendTo[message]`, this.state.sendTo.message);
    }
    formData.append("name", this.state.nomeArquivo);
    formData.append("file", this.state.arquivo);
    formData.append("deadline", this.state.prazoAss);

    API.post(NEW_DOCUMENT, formData, config)
      .then(() => {
        logEvent(analytics, 'new_document');
        showNotification(
          "",
          "Seu documento foi enviado ao(s) assinante(s)!",
          NOTIFICATION_TYPE.success,
          NOTIFICATION_POSITION.bottomLeft
        )
        this.limparUpload();
      })
      .catch((error) => {
        const result = error.response.data;
        if (result.code === "E022") {
          this.setState({
            error: {
              title: getTitle(result.code),
              message: result.error
            }
          })
        } else {
          handleError(error.response.data)
        }
      })
      .finally(() => this.setState({showProgress: false}));
  };

  cadastrarDocumentoEmLote() {
    this.setState({showProgress: true});

    let formData = new FormData(),
      config = { headers: { "content-type": "multipart/form-data" }};

    formData.append("nome", this.state.nomeArquivo ? this.state.nomeArquivo : this.state.tituloDocumento);
    formData.append("zip", this.state.arquivo);
    formData.append("prazoAssinaturas", this.state.prazoAss);

    API.post("documentos/lote", formData, config).then(() => {
      store.addNotification({
        title: "Enviado!",
        message: "Seus documentos foram cadastrados e logo logo serão enviados aos assinantes!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
      this.limparUpload();
    }).catch(() => {
      store.addNotification({
        title: "Ops!",
        message: "Não foi possível cadastrar os documentos em lote. Tente novamente mais tarde!",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }).finally(() => this.setState({showProgress: false}));
  };

  toggleTooltipNomeDocumento = () => this.setState({ tooltipNomeDocumento: !this.state.tooltipNomeDocumento });

  _renderDocumentoEmLote() {
    return (
      this.state.arquivo == null ?
        <UploadDocumento accept=".zip" selecionarDocumento={(arquivo) => this.selecionarDocumento(arquivo)} />
        : <div>
          <div style={{ marginBottom: "1rem" }}>
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="TooltipNomeDocumento"><i className="fas fa-file-signature"/> Nome do
                      documento:</label>
                    <input id="TooltipNomeDocumento" value={this.state.nomeArquivo}
                           onChange={(e) => this.setState({nomeArquivo: e.target.value})} className="form-control"/>
                  </div>
                </div>

                <div className="col-md-6">
                  <form>
                    <RegrasProcesso prazoAss={this.state.prazoAss} handlePrazoAss={(prazo) => this.setState({prazoAss: prazo})}/>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div style={{display: "flex", justifyContent: "flex-end", marginTop: 40}}>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.cadastrarDocumentoEmLote()}>
              { !this.state.showProgress ? <i className="fa fa-paper-plane"/> : <i className="fas fa-spinner fa-spin"/> } Enviar documento
            </button>
          </div>
        </div>
    )
  }

  _renderDocumentoUnitario() {
    return (
      this.state.arquivo == null ?
        <UploadDocumento accept=".pdf" selecionarDocumento={(arquivo) => this.selecionarDocumento(arquivo)} />
        :
        <>
          <NewDocumentTutorial
            show={this.state.isTourOpen}
            onClose={() => { this.setState({isTourOpen: false}) }}/>

          <div className="d-flex justify-content-between" style={{marginBottom: '2rem'}}>
            <a style={{marginRight: 8, color: "var(--au-orange)", cursor: 'pointer'}}
               onClick={() => this.setState({arquivo: null}) }>
              <i className="fas fa-chevron-left"/>
            </a>

            <div>
              <IconButton
                onClick={() => { this.setState({showPDF: true}) }}
                icon="fas fa-file-pdf" text="Ver PDF" />

              <span style={{width: '1rem'}} />

              <IconButton
                onClick={() => { this.setState({isTourOpen: true}) }}
                icon="fas fa-play" text="Abrir tutorial" />
            </div>
          </div>

          <div>
            <div style={{ marginBottom: "1rem" }}>
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group process-name">
                      <label htmlFor="TooltipNomeDocumento">
                        <i className="fas fa-file-signature"/> Nome do documento: <span className="au-red">*</span>
                      </label>
                      <input
                        id="TooltipNomeDocumento"
                        autoComplete="new-password"
                        type="text"
                        value={this.state.nomeArquivo}
                        onChange={(e) => this.setState({nomeArquivo: e.target.value})}
                        className="form-control" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <form>
                      <RegrasProcesso prazoAss={this.state.prazoAss} handlePrazoAss={(prazo) => this.setState({prazoAss: prazo})}/>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <hr/>

            <div className="row">
              <div className="col-md-6">
                <SignatariosDocumento
                  addSignatario={(contato) => this.addSignatario(contato)}
                  rmSignatario={(index) => this.rmSignatario(index)}
                  vincularPasta={(contato, pasta) => this.vincularPasta(contato, pasta)}
                  signatarios={this.state.signatarios}/>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <SendTo
                      addEmail={(email) => this.addEmail(email)}
                      removeEmail={(index) => this.removeEmail(index)}
                      emails={this.state.sendTo.emails}
                      addMessage={(message) => {
                        this.setState({
                          sendTo: {
                            ...this.state.sendTo,
                            message: message
                          }
                        })
                      }}
                      message={this.state.sendTo.message}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center" style={{marginTop: '2rem'}}>
              <Consumption />

              <button
                disabled={
                  this.state.signatarios.length === 0 || this.state.showProgress}
                type="button"
                className="btn btn-assinauai"
                onClick={() => this.cadastrarDocumento()}>
                { !this.state.showProgress ? <i className="fa fa-paper-plane"/> : <i className="fas fa-spinner fa-spin"/> } Enviar documento
              </button>
            </div>
          </div>
        </>
    )
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="card card-mobile">
              <div className="card-body">
                <div className="tab-content" id="custom-tabs-four-tabContent">
                  <div className="tab-pane fade active show" id="documentos-unitarios" role="tabpanel" aria-labelledby="documentos-unitarios-tab">
                    {this._renderDocumentoUnitario()}
                  </div>
                  <div className="tab-pane fade" id="documentos-lote" role="tabpanel" aria-labelledby="documentos-lote-tab">
                    {this._renderDocumentoEmLote()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          this.state.error !== null ?
            <ModalError
              title={this.state.error.title}
              message={this.state.error.message}
              positiveButton="Abrir tela de pagamento"
              negativeButton="Cancelar"
              positiveAction={() => history.push("/pagamento")}
              negativeAction={() => this.setState({error: null})} />
            :
            null
        }

        <Modal
          show={this.state.showPDF}
          fullscreen={true}
          onHide={() => this.setState({showPDF: false})}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.nomeArquivo}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{height: '750px'}}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={this.state.fileURL}
                  defaultScale={SpecialZoomLevel.PageFit}
                />
              </Worker>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

}

function NewDocumentTutorial({show, onClose}) {

  const { darkMode } = useContext(Context)
  const [showTour, setShowTour] = useState(false)

  useEffect(() => {
    setShowTour(show)
  }, [show])

  return (
    <Tour
      steps={newDocumentTutorial}
      isOpen={showTour}
      className={darkMode ? 'dark-mode' : ''}
      onRequestClose={onClose}
      accentColor={"#F1581B"} />
  )
}

export default NovoDocumento;
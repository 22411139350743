import React, {useState} from "react";

import history from "../../routers/History";
import API from "../../services/api";

import "../../styles/login.css";

import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {validateCNPJ, validateCPF} from "validations-br";
import InputMask from "react-input-mask";
import {CHECK_DOCUMENT} from "../../services/registrationService";
import getTitle from "../../utils/handleError";
import ErrorAlert from "../../components/utils/error-alert";
import SinglePage from "../../components/template/SinglePage";
import {getAnalytics, logEvent} from "firebase/analytics";

const queryString = require('query-string');

function CheckDocument () {

  const validationSchema = Yup.object({
    tipoPessoa: Yup.string()
      .required('Informe se você irá se cadastrar via CPF ou CNPJ'),
    cnpj: Yup.string()
      .when("tipoPessoa", {
        is: "juridica",
        then: Yup.string()
          .required("O campo CNPJ é obrigatório")
          .test(
            "is-cnpj",
            "CNPJ inválido",
            (value) => validateCNPJ(value)
          )
      }),
    cpf: Yup.string()
      .when("tipoPessoa", {
        is: "fisica",
        then: Yup.string()
          .required("O campo CPF é obrigatório")
          .test(
            "is-cpf",
            "CPF inválido",
            (value) => validateCPF(value)
          )
      })
  });
  const initialValues = {
    tipoPessoa: "fisica",
    cnpj: "",
    cpf: ""
  };

  const renderError = (message) => <div style={{display: 'block'}} className="invalid-feedback">{message}</div>
  const [progress, setProgress] = useState(false)
  const [error, setError] = useState(null)
  const analytics = getAnalytics()

  async function checkDocument (document) {
    // region check_document
    logEvent(analytics, 'check_document')
    // endregion

    setError(null);
    setProgress(true);
    await API.post(CHECK_DOCUMENT, {
      document
    })
      .then(() => {
        const { token } = queryString.parseUrl(window.location.href).query
        history.push('/nome', {token, document});
      })
      .catch((error) => {
        const result = error.response.data;
        setError({
          title: getTitle(result.code),
          message: result.error
        });
      })
      .finally(() => setProgress(false));
  }

  return (
    <SinglePage>
      <div style={{marginBottom: 15}}>
        <p className="title-md">Nova conta</p>
        <span className="description-md">
            <strong>3º Passo:</strong> Vamos começar o seu cadastro!<br/>
            Informe abaixo o seu <strong>CPF</strong> ou <strong>CNPJ</strong> para continuarmos com o seu cadastro.
          </span>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          let document = values.tipoPessoa === 'fisica' ? values.cpf : values.cnpj
          document = document.replace(/[^0-9]/g, '')
          checkDocument(document);
        }}>
        {(formik) => (
          <Form>
            <ErrorAlert error={error} />

            <div className="form-group">
              <label>
                <Field type="radio" name="tipoPessoa" value='fisica' /> Pessoa física
              </label>
              <label style={{marginLeft: 15}}>
                <Field type="radio" name="tipoPessoa" value='juridica' /> Pessoa jurídica
              </label>
              <ErrorMessage name="tipoPessoa" render={renderError} />
            </div>

            <div className="form-group" style={{display: formik.values.tipoPessoa  === 'fisica' ? 'block' : 'none'}}>
              <label className="label">
                CPF: <span className="au-red">*</span>
              </label>
              <InputMask
                mask="999.999.999-99"
                value={formik.values.cpf}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur} >
                {() => (
                  <Field
                    className="form-control"
                    name="cpf"
                  />
                )}
              </InputMask>
              <ErrorMessage name="cpf" render={renderError} />
            </div>

            <div className="form-group" style={{display: formik.values.tipoPessoa  === 'juridica' ? 'block' : 'none'}}>
              <label className="label">
                CNPJ: <span className="au-red">*</span>
              </label>
              <InputMask
                mask="99.999.999/9999-99"
                value={formik.values.cnpj}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur} >
                {() => (
                  <Field
                    className="form-control"
                    name="cnpj"
                  />
                )}
              </InputMask>
              <ErrorMessage name="cnpj" render={renderError} />
            </div>

            <div className="botao-login">
              <button type="submit" disabled={!formik.isValid || progress} className="btn btn-assinauai btn-block">
                { progress ? <span><i className="fa fa-spinner fa-spin"/></span> : 'Continuar' }
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </SinglePage>
  )
}

export default CheckDocument;

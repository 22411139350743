import React, {useContext} from "react";

import "./button.css"
import {Context} from "../../../routers/Context/AuthContext";

export default function IconButton({onClick, icon, text}) {

  const { darkMode } = useContext(Context)

  return (
    <button className={`btn au-button ${darkMode ? 'dark-mode' : ''}`}
       onClick={onClick}>
      <span><i className={icon}/> {text}</span>
    </button>

  )

}